<template>
  <div class="page-wrapper">
    <b-container fluid>
      <bo-page-title></bo-page-title>
      <b-card no-body>
        <b-card-header>
          <b-row>
            <b-col lg="8">
              <h5 class="card-title">Add User</h5>
            </b-col>
          </b-row>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col lg="6">
              <b-form-group>
                <label>Legal Name <span class="text-danger mr5">*</span></label>
                <b-form-input id="input-formatter" v-model="text1" placeholder="e.g Privacy Polic" :formatter="formatter">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="12">
              <b-form-group  label-for="input-formatter" class="mb-3">
                <label>Content<span class="text-danger mr5">*</span></label>
                <b-form-group class="mb-3">
                <ckeditor value="Hello, World!"></ckeditor>
                </b-form-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
        <b-card-footer>
          <b-row>
            <b-col lg="12" class="text-right">
              <button type="submit" class="btn  btn-rounded  btn-default mr-3">Cancel</button>
              <button type="submit" class="btn  btn-rounded  btn-success">Submit</button>
            </b-col>
          </b-row>
        </b-card-footer>
      </b-card>
    </b-container>
  </div>
</template>
<script>
  import GlobalVue from '../libs/Global.vue'
  import BoPageTitle from '@/components/BoPageTitle.vue'

  export default {
    extends: GlobalVue,
    components: {
      BoPageTitle,
    },
    data() {
      return {
        selected: 'Y',
        options: [{
            item: 'Y',
            name: 'Active'
          },
          {
            item: 'N',
            name: 'Inactive'
          }
        ]
      }
    }
  }
</script>